.Navbar {
    background: transparent !important;
    width:100%;
    height:10vh;
    display:flex;
    flex-direction:row;
    z-index:2;
    position:relative;
    top:0 !important;
    left:0 !important;
    margin: 2% 0% !important;
}

.Navbar .leftSide {
    flex:30%;
    background: transparent !important;
    margin: 0% 0% 0% 2%!important;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
}

img.logo-img {
    height: calc(1vw + 0.7vh + 0.8vmin);
    width: auto;
}

.Navbar .rightSide {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-end;
    flex:70%;
    margin: 0% 2% 0% 0%!important;
    background: transparent !important;
}

.Navbar .rightSide .links {
    height:auto !important;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    background: transparent !important;
}

.Navbar .rightSide .links .nav {
    max-height: 12vh !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: transparent !important;
}

.Navbar .rightSide .links .menu-item {
    text-decoration:none;
    color:white;
    font-size:calc(0.5vw + 0.2vh + 0.9vmin);
    width: auto;
    margin-left:25px !important;
    letter-spacing:0.5px;
    transition: all .2s ease-in-out;
   
}

.Navbar .rightSide .links .menu-item:hover {

    background:white;
    color:black;
}

a.btn-rounded{
    border:solid white 1px;
    padding: 15px 35px !important;
    border-radius:0.5rem;
    font-family: "FLURO Light";
    font-size:17px;
    text-transform:uppercase;
}



@media only screen and (max-width: 850px) {

    .Navbar {
        background: transparent !important;
        width:100%;
        height:18vh;
        display:flex;
        flex-direction:column;
        z-index:2;
        position:relative;
        top:0 !important;
        left:0 !important;
        justify-content:space-around;
        align-items:center;
        
    }

    .home-header .top-area {

        justify-content: center;

    }

    .home-header .top-area h1 {

        text-align: center;
      
    }

    .bottom-area {
        width: 100%;
        height: 15%;

    }

    .Navbar .rightSide {

        flex: 0% 1;
       
    }

    img.logo-img {
        height: auto;
        width: 100%;
     
    }

    .Navbar .rightSide .links .menu-item {
        font-size:calc(0.5vw + 1.1vh + 1.0vmin);
        margin: 0 !important;
    }

    .Navbar .rightSide .links .nav {
        max-height: 10vh !important;
       
    }

    a.btn-rounded{
        border:solid white 1px;
        padding: 8px 30px !important;
        border-radius:0.5rem;
        font-family: "FLURO Light";
        font-size:17px;
        text-transform:uppercase;
    }
    
}

@media only screen and (max-width: 750px) {
    .Navbar {
        background: transparent !important;
        width:100%;
        height:18vh;
        display:flex;
        flex-direction:column;
        z-index:2;
        position:relative;
        top:0 !important;
        left:0 !important;
        justify-content:space-around;
        align-items:center;
        
    }

    .home-header .top-area {

        justify-content: center;

    }

    .home-header .top-area h1 {

        text-align: center;
      
    }

    .bottom-area {
        width: 100%;
        height: 15%;

    }

    .Navbar .rightSide {

        flex: 0% 1;
       
    }

    img.logo-img {
        height: auto;
        width: 100%;
     
    }

    .Navbar .rightSide .links .menu-item {
        font-size:calc(0.5vw + 1.1vh + 1.0vmin);
        margin: 0 !important;
    }

    .Navbar .rightSide .links .nav {
        max-height: 10vh !important;
       
    }

    a.btn-rounded{
        border:solid white 1px;
        padding: 8px 30px !important;
        border-radius:0.5rem;
        font-family: "FLURO Light";
        font-size:17px;
        text-transform:uppercase;
    }
}