.home {
    display:flex;
    flex-direction:column;
    height:82vh;
    overflow:hidden;
    overflow:hidden;
    justify-content:center;
    align-items:center;
    color:#fff;
    background:black;
    margin: 0!important;
    padding: 0 !important; 
 }

 /***
 *     HEADER AREA
 *****************/

 .home-header {
    margin-top: 0px !important;
    height:85vh;
    background:black;
    position:relative;
    top:0 !important;
    z-index:1;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
}

.home-header .top-area {
    width:100%;
    height:75%;
    background:transparent;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    padding: 0 2% !important;
}

.home-header .top-area h1 {
    font-size:calc(0.5vw + 4.2vh + 4vmin);
    color: #fff;
    line-height: calc(0.5vw + 4.2vh + 5.5vmin);
    width:80%;
    text-align:left;
    font-family: "valky-normal";
}

@media (max-width: 600px) {

    .home-header .top-area h1 {
        font-size:40px !important;
        line-height:50px !important;
        color: #fff;
        line-height: auto;
        width:80%;
        text-align:left;
        font-family: "valky-normal";
    }
    

}



 .bottom-area {
    width:100%;
    height:25%;
    background:transparent;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:flex-start;
    padding: 0 2% !important;
    
}

.bottom-area h3 {
    color: rgb(219, 219, 219);
    font-weight:800;
    font-family: "FLURO Semibold";
    font-size:calc(0.5vw + 1.2vh + 1.4vmin);
    text-align: right;
}

.bottom-area p {
    font-family: "FLURO Light";
    letter-spacing:0.3px;
    font-size:calc(0.5vw + 0.8vh + 0.9vmin);
    line-height:calc(0.5vw + 1vh + 1.1vmin);
}

.bottom-area .section {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
}

.bottom-area .section-end {

    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-end;

}

.last-projects {
    width:100%;
    padding: 0 2% !important;
  
}

.fullwidth-proj img{
    width:100% !important;
    border-radius:0.5rem;
}

.fullwidth-proj .proj-info {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    margin: 1% 0% !important;
    
}

.fullwidth-proj .proj-info h3 {
    font-family: "FLURO Semibold";
    font-size:22px;
}

.fullwidth-proj .proj-info h4 {
    font-family: "FLURO-Regular";
    font-size:22px;
}

.last-projects .category-title {
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    height:auto;
    width:350px;
    margin-bottom: 2% !important;
    

}

.last-projects .category-title img {
    height:30px;
    width:auto;
}

.last-projects h2 {
    font-size:30px;
    width:85%;
    font-weight:500;
    text-align:left;
    font-family: "FLURO Semibold";
}




 .event-date h4 {
    font-size:20px;
    font-weight:200 !important;
 }
 

 /* section 1 transition experience */

 .section-exp {
     width: 100%;
     height:100vh;
     background:transparent;
     display:flex;
     flex-direction:column;
     justify-content:center;
     align-items:center;
 }

 .section-exp .area-text {
     width:80%;
     height:50vh;
     background:transparent;
     display:flex;
     flex-direction:column;
     justify-content:center;
     align-items:center;
 }

 .section-exp .area-text h1 {
     font-size:80px;
     font-family: 'Raleway', sans-serif;
     font-weight:400;
     background:transparent !important;
 }

 .section-exp .area-text h1 span {
    font-family: "PPEiko-LightItalic";
    background:transparent;
 }

 /* event description */

 .section-event {
     position:relative;
     height:auto;
     width:100%;
     display:flex;
     flex-direction:column;
     justify-content:center;
     align-items:center;
 }

 .section-event .event-part1 {
        height:70vh;
        width:80%;
        background:transparent;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
 }
 .section-event .event-part2 {
    height:70vh;
    width:90%;
    background:transparent;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top: 30vh !important;
    margin-bottom: 30vh !important;
}

.section-event .event-part3 {
    height:auto;
    width:90%;
    background:transparent;
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    align-items:center;
    margin-top:150px !important;
}

.section-event .event-part4 {

    height:70vh;
    width:90%;
    background:transparent;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    margin-top:150px !important;
}

 .section-event  h2 {
    font-size:25px;
    font-weight:200 !important;
    text-transform:uppercase;
    margin-bottom:25px !important;
 }

 .section-event p {
    font-family: "FLURO-Regular";
    font-size:40px;
}

.section-event p span {
    font-family: "PPEiko-LightItalic";
    font-size:35px;
}

.event-part2 .venue-area {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
}

.venue-area img{
    flex:50%;
    height:auto;
    border-radius:0.5rem;
}

.venue-area .text-venue {
    flex:50%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    text-align:left;
    margin: 0% 3% !important;
}

.venue-area .text-venue h1 {
    font-weight:200;
    font-size:35px;
    margin-bottom:20px !important;
}

.venue-area .text-venue h1 span {
    font-family: "PPEiko-LightItalic";
    font-size:35px;
}

.venue-area .text-venue p {
    font-weight:200;
    font-size:30px;
    font-family: "FLURO Light";
}



.section-event .event-part3 .wrap-collective {
    position:relative;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    height:auto;
    z-index:3;
    background:transparent;
    left: -100px !important;
}

.section-event .event-part3 .wrap-collective h1 {
    font-size:90px;
    flex:40%;
    font-weight:500;
    height:auto;
    background:transparent;
    text-align:left;
    
}

.section-event .event-part3 .wrap-collective h1 span {
    font-size:90px;
    flex:40%;
    font-weight:light;
    font-weight:200;
    font-family: "PPEiko-LightItalic";
    background:transparent;

}

.section-event .event-part3 img {
    position:relative;
    width:60%;
    height:auto;
    top:-50px !important;
    left:80px !important;
    z-index:2;
}

.section-event .event-part3 p {
    font-weight:200;
    font-size:30px;
    font-family: "FLURO Light";
    width:80% !important;
    text-align:right;
}

.section-event .event-part3 p span {
    font-weight:200;
    font-family: "PPEiko-LightItalic";
    background:transparent;
    font-size:30px;
}

.section-event .event-part4 h2 {
    text-align:left;
    width:200px;
}

/* carousel */

.item {
    min-height:auto;
    min-width:25%;
    padding: 20px;
    margin: 2% 0% !important;
}

.item img {
    width:100%;
    height: auto;
    border-radius:0.5rem;
    padding: 5px !important;
    
}



.item-fashion {
    height:20rem;
    min-width:15rem;
    padding: 20px;
}

.item-fashion img {
    width:100%;
    height: 100%;
    border-radius:0.5rem;
    padding: 5px !important;
}



.inner-carousel {
    display:flex;
    z-index:1;
    width:100%;

}

.carousel {
    width:100%;
    overflow:hidden;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    padding: 0 2% !important;
}

.carousel h1 {
    font-size:75px;
    font-family: "PPEiko-LightItalic";
    margin-left:25px !important;
    z-index:2;
    background:transparent;
}

.carousel-center {

    overflow:hidden;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    padding: 0 2% !important;
}

.carousel-center h1 {
    font-size:75px;
    font-family: "PPEiko-LightItalic";
    margin-left:25px !important;
    z-index:2;
    background:transparent;
}

.carousel-right {

    overflow:hidden;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-end;
    padding: 0 2% !important;
}

.carousel-right h1 {
    font-size:75px;
    font-family: "PPEiko-LightItalic";
    margin-right:25px !important;
    z-index:2;
    background:transparent;
}

