.App {
  text-align: center;
}

* {
  background: black;
  margin: 0!important;
  padding: 0 !important;
  overflow-x:hidden;
  color:white;
}

footer {
  position:absolute;
  height:10vh;
  margin: 1% 0% !important;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  background:transparent;
  width:100%;
}

footer p {
  letter-spacing:0.5px;
  font-size: 15px;
}






