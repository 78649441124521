@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* PP EIKO FONT */

@font-face {
  font-family: "PPEiko-BlackItalic";
  src: url("./fonts/PPEiko-BlackItalic.otf") format("opentype");
}
@font-face {
  font-family: "PPEiko-Heavy";
  src: url("./fonts/PPEiko-Heavy.otf") format("opentype");
}
@font-face {
  font-family: "PPEiko-LightItalic";
  src: url("./fonts/PPEiko-LightItalic.otf") format("opentype");
}

@font-face {
  font-family: "PPEiko-Medium";
  src: url("./fonts/PPEiko-Medium.otf") format("opentype");
}

@font-face {
  font-family: "PPEiko-Thin";
  src: url("./fonts/PPEiko-Thin.otf") format("opentype");
}

/* FLURO FONT */

@font-face {
  font-family: "FLURO Bold Outline";
  src: url("./fonts/FLURO-Bold-Outline.otf") format("opentype");
}
@font-face {
  font-family: "FLURO Bold";
  src: url("./fonts/FLURO-Bold.otf") format("opentype");
}
@font-face {
  font-family: "FLURO Light";
  src: url("./fonts/FLURO-Light.otf") format("opentype");
}

@font-face {
  font-family: "FLURO Semibold";
  src: url("./fonts/FLURO-Semibold.otf") format("opentype");
}

@font-face {
  font-family: "FLURO-Regular";
  src: url("./fonts/FLURO-Regular.otf") format("opentype");
}

@font-face {
  font-family: "valky-normal";
  src: url("./fonts/valky-regular.woff")
    format("woff"),
    url("./fonts/valky-regular.woff2")
    format("woff2");
}

@font-face {
  font-family: "NeueMontreal-Regular";
  src: url("./fonts/NeueMontreal-Regular.woff")
    format("woff"),
    url("./fonts/NeueMontreal-Regular.woff2")
    format("woff2");
}

@font-face {
  font-family: "NeueMontreal-Light";
  src: url("./fonts/NeueMontreal-Light.woff")
    format("woff"),
    url("./fonts/NeueMontreal-Light.woff2")
    format("woff2");
}

  * {
    font-family: 'Raleway', sans-serif;
  }